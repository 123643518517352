import type { Ref } from 'vue';
import type { LocationQueryValue } from 'vue-router';

import type { Filter } from '~/src/classes.ts';

const convertQueryToSingleString = (query: LocationQueryValue | LocationQueryValue[]): string => {
    if (Array.isArray(query)) {
        return query.join('');
    }
    return query ?? '';
};

export default () => {
    const route = useRoute();
    const router = useRouter();

    const filter: Ref<Filter> = ref({ text: '', category: '', moderation: undefined });

    onBeforeRouteUpdate((to) => {
        if (to.hash) {
            return { query: { filter: to.hash.substring(1).replace(/=$/, '') }, replace: true };
        }
    });

    watchEffect(() => {
        filter.value = {
            ...filter.value,
            text: convertQueryToSingleString(route.query.filter),
            category: convertQueryToSingleString(route.query.category),
        };
    });

    watch(filter, async () => {
        const query = structuredClone(route.query);
        delete query.filter;
        delete query.category;
        if (filter.value.text) {
            query.filter = filter.value.text;
        }
        if (filter.value.category) {
            query.category = filter.value.category;
        }
        if (JSON.stringify(query) !== JSON.stringify(route.query)) {
            await router.replace({ query });
        }
    }, {
        deep: true,
    });

    return filter;
};
