<script setup lang="ts" generic="T extends Entry">
import type { Entry, Filter } from '~/src/classes.ts';

const props = defineProps<{
    kind: 'sources' | 'nouns' | 'terms' | 'inclusive';
    entries: T[];
    moderationFilters?: Filter['moderation'][];
}>();

const modelValue = defineModel<Filter['moderation']>();

const entriesCountApproved = computed(() => {
    return props.entries.filter((entry) => entry.approved).length;
});

const entriesCountForFilter = (moderationFilter: Filter['moderation']) => {
    const filter = { text: '', category: '', moderation: moderationFilter };
    return props.entries.filter((entry) => entry.matches(filter)).length;
};
</script>

<template>
    <section v-if="$isGranted(kind)">
        <div class="alert alert-info">
            <strong>{{ entriesCountApproved }}</strong>
            approved
            <div class="d-flex gap-1">
                <button
                    v-for="moderationFilter of moderationFilters"
                    :key="moderationFilter"
                    :class="[
                        'btn btn-secondary',
                        modelValue === moderationFilter ? 'active' : '',
                        'd-flex justify-content-center align-items-center gap-1',
                    ]"
                    @click="modelValue = modelValue === moderationFilter ? undefined : moderationFilter"
                >
                    <strong>{{ entriesCountForFilter(moderationFilter) }}</strong>
                    {{ moderationFilter }}
                </button>
            </div>
        </div>
    </section>
</template>
